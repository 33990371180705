<template>
	<div class="page-service-agreement-wrap">
		<div class="page-service-agreement">
			<h1>{{ $t('WDNPC_WEB_USER_AGREEMENT', { text: '用户协议' }) }}</h1>
			<p>
				{{
					$t('WDNPC_WEB_BEIJING_WEIDONG_LINGHONG_EDUCATION_TECHNOLOGY_CO_LTD', {
						text:
							'北京伟东凌鸿教育科技有限公司及各关联公司在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。',
					})
				}}
			</p>
			<p>
				{{
					$t('WDNPC_WEB_THIS_AGREEMENT_STIPULATES_THAT_THE_RIGHTS_AND', {
						text:
							'本协议约定北京伟东凌鸿教育科技有限公司及各关联公司与用户之间关于“北京伟东凌鸿教育科技有限公司及各关联公司”软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。本协议可由北京伟东凌鸿教育科技有限公司及各关联公司随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本网站查阅最新版协议条款。在北京伟东凌鸿教育科技有限公司及各关联公司修改协议条款后，如果用户不接受修改后的条款，请立即停止使用北京伟东凌鸿教育科技有限公司及各关联公司提供的服务，用户继续使用北京伟东凌鸿教育科技有限公司及各关联公司提供的服务将被视为接受修改后的协议。',
					})
				}}
			</p>
			<p>{{ $t('WDNPC_WEB_ONE_ACCOUNT_REGISTRATION', { text: '一、账号注册' }) }}</p>
			<p>
				1{{
					$t('WDNPC_WEB_BEFORE_USING_THIS_SERVICE_USERS_NEED_TO', {
						text:
							'、用户在使用本服务前需要注册一个“北京伟东凌鸿教育科技有限公司及各关联公司”账号。“北京伟东凌鸿教育科技有限公司及各关联公司”账号应当使用手机号码绑定注册，请用户使用尚未与“北京伟东凌鸿教育科技有限公司及各关联公司”账号绑定的手机号码，以及未被北京伟东凌鸿教育科技有限公司及各关联公司根据本协议封禁的手机号码注册“北京伟东凌鸿教育科技有限公司及各关联公司”账号。北京伟东凌鸿教育科技有限公司及各关联公司可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。',
					})
				}}
			</p>
			<p>
				2{{
					$t('WDNPC_WEB_IN_VIEW_OF_THE_BINDING_REGISTRATION_METHOD', {
						text:
							'、鉴于“北京伟东凌鸿教育科技有限公司及各关联公司”账号的绑定注册方式，您同意北京伟东凌鸿教育科技有限公司及各关联公司在注册时将自动提取您的手机号码及手机设备识别码等信息用于注册。',
					})
				}}
			</p>
			<p>
				3{{
					$t('WDNPC_WEB_WHEN_USERS_REGISTER_AND_USE_THIS_SERVICE', {
						text:
							'、在用户注册及使用本服务时，北京伟东凌鸿教育科技有限公司及各关联公司可能需要搜集能识别用户身份的个人信息以便北京伟东凌鸿教育科技有限公司及各关联公司可以在必要时联系用户，或为用户提供更好的使用体验。北京伟东凌鸿教育科技有限公司及各关联公司搜集的信息包括但不限于用户的姓名、性别、地址、学校情况；北京伟东凌鸿教育科技有限公司及各关联公司同意对这些信息的使用将受限于北京伟东凌鸿教育科技有限公司及各关联公司发布的个人隐私政策。',
					})
				}}
			</p>
			<p>{{ $t('WDNPC_WEB_II_CONTENT_SPECIFICATION', { text: '二、内容规范' }) }}</p>
			<p>
				1{{
					$t('WDNPC_WEB_THE_CONTENT_MENTIONED_IN_THIS_ARTICLE_REFERS', {
						text:
							'、本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称等注册信息及认证资料，以及其他使用账号或本服务所产生的内容。',
					})
				}}
			</p>
			<p>
				2{{
					$t('WDNPC_WEB_USERS_ARE_NOT_ALLOWED_TO_USE_THE', {
						text:
							'、用户不得利用“北京伟东凌鸿教育科技有限公司及各关联公司”账号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：',
					})
				}}
			</p>
			<p>{{ $t('WDNPC_WEB_OPPOSING_THE_BASIC_PRINCIPLES_DETERMINED_BY_THE', { text: '反对宪法所确定的基本原则的；' }) }}</p>
			<p>
				{{
					$t('WDNPC_WEB_ENDANGERING_NATIONAL_SECURITY_LEAKING_STATE_SECRETS_OVERTHROWING', {
						text: '危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
					})
				}}
			</p>
			<p>{{ $t('WDNPC_WEB_DAMAGE_NATIONAL_HONOR_AND_INTERESTS', { text: '损害国家荣誉和利益的；' }) }}</p>
			<p>{{ $t('WDNPC_WEB_INCITING_ETHNIC_HATRED_ETHNIC_DISCRIMINATION_AND_DESTROYING', { text: '煽动民族仇恨、民族歧视，破坏民族团结的；' }) }}</p>
			<p>{{ $t('WDNPC_WEB_DESTROYING_THE_NATIONAL_RELIGIOUS_POLICY_AND_PROMOTING', { text: '破坏国家宗教政策，宣扬邪教和封建迷信的；' }) }}</p>
			<p>{{ $t('WDNPC_WEB_SPREADING_RUMORS_TO_DISTURB_SOCIAL_ORDER_AND', { text: '散布谣言，扰乱社会秩序，破坏社会稳定的；' }) }}</p>
			<p>
				{{
					$t('WDNPC_WEB_SPREADING_OBSCENE_PORNOGRAPHY_GAMBLING_VIOLENCE_MURDER_TERROR', {
						text: '散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；',
					})
				}}
			</p>
			<p>{{ $t('WDNPC_WEB_INSULT_OR_SLANDER_OTHERS_AND_INFRINGE_UPON', { text: '侮辱或者诽谤他人，侵害他人合法权益的；' }) }}</p>
			<p>{{ $t('WDNPC_WEB_INFORMATION_CONTAINING_OTHER_CONTENTS_PROHIBITED_BY_LAWS', { text: '含有法律、行政法规禁止的其他内容的信息；' }) }}</p>
			<p>{{ $t('WDNPC_WEB_CONTAINING_ANY_SEXUAL_OR_SEXUAL_SUGGESTION', { text: '含有任何性或性暗示的；' }) }}</p>
			<p>{{ $t('WDNPC_WEB_CONTAINING_THE_THREAT_OF_ABUSE_AND_INTIMIDATION', { text: '含有辱骂、恐吓、威胁内容的；' }) }}</p>
			<p>{{ $t('WDNPC_WEB_CONTAINING_MALICIOUS_INFORMATION_THAT_HARASSES_SPAM_ADS', { text: '含有骚扰、垃圾广告、恶意信息、诱骗信息的；' }) }}</p>
			<p>
				{{ $t('WDNPC_WEB_INFRINGEMENT_OF_OTHERS_RIGHT_OF_REPUTATION_PORTRAIT', { text: '侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；' }) }}
			</p>
			<p>
				{{
					$t('WDNPC_WEB_CONTAINS_OTHER_INFORMATION_THAT_INTERFERES_WITH_THE', {
						text: '含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。',
					})
				}}
			</p>
			<p>{{ $t('WDNPC_WEB_THREE_ACCOUNT_MANAGEMENT', { text: '三、帐户管理' }) }}</p>
			<p>
				1、{{
					$t('WDNPC_WEB_THE_OWNERSHIP_OF_THE_ACCOUNTS_OF_BEIJING', {
						text:
							'“北京伟东凌鸿教育科技有限公司及各关联公司”账号的所有权归北京伟东凌鸿教育科技有限公司及各关联公司所有，用户完成申请注册手续后，获得“北京伟东凌鸿教育科技有限公司及各关联公司”账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。北京伟东凌鸿教育科技有限公司及各关联公司因经营需要，有权回收用户的“北京伟东凌鸿教育科技有限公司及各关联公司”账号。',
					})
				}}
			</p>
			<p>
				2{{
					$t('WDNPC_WEB_USERS_CAN_CHANGE_AND_DELETE_THE_REGISTRATION', {
						text:
							'、用户可以更改、删除“北京伟东凌鸿教育科技有限公司及各关联公司”帐户上的个人资料、注册信息及传送内容等，但需注意，删除有关信息的同时也会删除用户储存在系统中的文字和图片。用户需承担该风险。',
					})
				}}
			</p>
			<p>
				3{{
					$t('WDNPC_WEB_THE_USER_HAS_THE_RESPONSIBILITY_TO_PROPERLY', {
						text:
							'、用户有责任妥善保管注册账号信息及账号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册账号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的账号或密码。在用户怀疑他人使用其账号或密码时，用户同意立即通知北京伟东凌鸿教育科技有限公司及各关联公司。',
					})
				}}
			</p>
			<p>
				4{{
					$t('WDNPC_WEB_THE_USER_SHALL_ABIDE_BY_THE_TERMS', {
						text:
							'、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，北京伟东凌鸿教育科技有限公司及各关联公司在通知用户后有权依据协议中断或终止对违约用户“北京伟东凌鸿教育科技有限公司及各关联公司”账号提供服务。同时，北京伟东凌鸿教育科技有限公司及各关联公司保留在任何时候收回“北京伟东凌鸿教育科技有限公司及各关联公司”账号、用户名的权利。',
					})
				}}
			</p>
			<p>
				5{{
					$t('WDNPC_WEB_IF_THE_USER_REGISTERS_THE_ACCOUNT_OF', {
						text:
							'、如用户注册“北京伟东凌鸿教育科技有限公司及各关联公司”账号后一年不登录，通知用户后，北京伟东凌鸿教育科技有限公司及各关联公司可以收回该账号，以免造成资源浪费，由此造成的不利后果由用户自行承担。',
					})
				}}
			</p>
			<p>{{ $t('WDNPC_WEB_IV_INTELLECTUAL_PROPERTY_DECLARATION', { text: '四、知识产权声明' }) }}</p>
			<p>
				1{{
					$t('WDNPC_WEB_UNLESS_OTHERWISE_STATED_THE_COPYRIGHT_PATENT_RIGHT', {
						text:
							'、除另有特别声明外，北京伟东凌鸿教育科技有限公司及各关联公司提供本服务时所依托软件的著作权、专利权及其他知识产权均归北京伟东凌鸿教育科技有限公司及各关联公司所有。',
					})
				}}
			</p>
			<p>
				2{{
					$t('WDNPC_WEB_BEIJING_WEIDONG_LINGHONG_EDUCATION_TECHNOLOGY_CO_LTD_GRX', {
						text:
							'、北京伟东凌鸿教育科技有限公司及各关联公司在本服务中所涉及的图形、文字或其组成，以及其他北京伟东凌鸿教育科技有限公司及各关联公司标志及产品、服务名称（以下统称“北京伟东凌鸿教育科技有限公司及各关联公司标识”），其著作权或商标权归北京伟东凌鸿教育科技有限公司及各关联公司所有。未经北京伟东凌鸿教育科技有限公司及各关联公司事先书面同意，用户不得将北京伟东凌鸿教育科技有限公司及各关联公司标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理北京伟东凌鸿教育科技有限公司及各关联公司标识的行为。',
					})
				}}
			</p>
			<p>
				3{{
					$t('WDNPC_WEB_THE_ABOVE_AND_ANY_OTHER_INTELLECTUAL_PROPERTY', {
						text:
							'、上述及其他任何北京伟东凌鸿教育科技有限公司及各关联公司或相关广告商依法拥有的知识产权均受到法律保护，未经北京伟东凌鸿教育科技有限公司及各关联公司或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。',
					})
				}}
			</p>
			<p>{{ $t('WDNPC_WEB_FIVE_LEGAL_LIABILITY', { text: '五、法律责任' }) }}</p>
			<p>
				1{{
					$t('WDNPC_WEB_IF_BEIJING_WEIDONG_LINGHONG_EDUCATION_TECHNOLOGY_CO', {
						text:
							'、如果北京伟东凌鸿教育科技有限公司及各关联公司发现或收到他人举报或投诉用户违反本协议约定的，北京伟东凌鸿教育科技有限公司及各关联公司有权不经通知随时对相关内容，包括但不限于用户资料进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁',
					})
				}}
				{{ $t('WDNPC_WEB_PUNISHMENT_FOR_DEVICE_BLOCKING_AND_NOTIFICATION_OF', { text: '、设备封禁 、功能封禁的处罚，且通知用户处理结果。' }) }}
			</p>
			<p>
				2{{
					$t('WDNPC_WEB_THE_USER_UNDERSTANDS_AND_AGREES_THAT_BEIJING', {
						text:
							'、用户理解并同意，北京伟东凌鸿教育科技有限公司及各关联公司有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿北京伟东凌鸿教育科技有限公司及各关联公司与合作公司、关联公司，并使之免受损害。',
					})
				}}
			</p>
			<p>{{ $t('WDNPC_WEB__FORCE_MAJEURE_AND_OTHER_REASONS_FOR', { text: '六、不可抗力及其他免责事由' }) }}</p>
			<p>
				1{{
					$t('WDNPC_WEB_USERS_UNDERSTAND_AND_CONFIRM_THAT_IN_THE', {
						text:
							'、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，北京伟东凌鸿教育科技有限公司及各关联公司将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，北京伟东凌鸿教育科技有限公司及各关联公司及合作单位在法律允许的范围内免责。',
					})
				}}
			</p>
			<p>
				2{{
					$t('WDNPC_WEB_THIS_SERVICE_LIKE_MOST_INTERNET_SERVICES_IS', {
						text:
							'、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。',
					})
				}}
			</p>
			<p>
				3{{
					$t('WDNPC_WEB_THE_USER_UNDERSTANDS_AND_CONFIRMS_THAT_THE', {
						text:
							'、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，北京伟东凌鸿教育科技有限公司及各关联公司不承担任何责任。',
					})
				}}
			</p>
			<p>
				4{{
					$t('WDNPC_WEB_THE_USER_UNDERSTANDS_AND_CONFIRMS_THE_EXISTENCE', {
						text:
							'、用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，北京伟东凌鸿教育科技有限公司及各关联公司不承担任何责任。',
					})
				}}
			</p>
			<p>
				5{{
					$t('WDNPC_WEB_USERS_UNDERSTAND_AND_CONFIRM_THAT_BEIJING_WEIDONG', {
						text:
							'、用户理解并确认，北京伟东凌鸿教育科技有限公司及各关联公司需要定期或不定期地对“北京伟东凌鸿教育科技有限公司及各关联公司”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，北京伟东凌鸿教育科技有限公司及各关联公司无需为此承担任何责任，但北京伟东凌鸿教育科技有限公司及各关联公司应事先进行通告。',
					})
				}}
			</p>
			<p>
				6{{
					$t('WDNPC_WEB_BEIJING_WEIDONG_LINHONG_EDUCATION_TECHNOLOGY_CO_LTD', {
						text:
							'、北京伟东凌鸿教育科技有限公司及各关联公司依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成北京伟东凌鸿教育科技有限公司及各关联公司的义务或承诺，北京伟东凌鸿教育科技有限公司及各关联公司不能保证及时发现违法违规或违约行为或进行相应处理。',
					})
				}}
			</p>
			<p>
				7{{
					$t('WDNPC_WEB_USERS_UNDERSTAND_AND_CONFIRM_THE_QUALITY_DEFECTS', {
						text:
							'、用户理解并确认，对于北京伟东凌鸿教育科技有限公司及各关联公司向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，北京伟东凌鸿教育科技有限公司及各关联公司无需承担任何责任：',
					})
				}}
			</p>
			<p>
				{{
					$t('WDNPC_WEB_BEIJING_WEIDONG_LINHONG_EDUCATION_TECHNOLOGY_CO_LTD_BRR', {
						text: '北京伟东凌鸿教育科技有限公司及各关联公司向用户免费提供的服务；',
					})
				}}
			</p>
			<p>
				{{
					$t('WDNPC_WEB_ANY_PRODUCTS_OR_SERVICES_PRESENTED_TO_USERS', {
						text: '北京伟东凌鸿教育科技有限公司及各关联公司向用户赠送的任何产品或者服务。',
					})
				}}
			</p>
			<p>
				8{{
					$t('WDNPC_WEB_UNDER_NO_CIRCUMSTANCES_SHALL_BEIJING_WEIDONG_LINGHONG', {
						text:
							'、在任何情况下，北京伟东凌鸿教育科技有限公司及各关联公司均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用“北京伟东凌鸿教育科技有限公司及各关联公司”或本服务而遭受的利润损失，承担责任（即使北京伟东凌鸿教育科技有限公司及各关联公司已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，北京伟东凌鸿教育科技有限公司及各关联公司对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用北京伟东凌鸿教育科技有限公司及各关联公司提供的服务而支付给北京伟东凌鸿教育科技有限公司及各关联公司的费用(如有',
					})
				}})。
			</p>
			<p>{{ $t('WDNPC_WEB__TERMINATION_OF_SERVICE_CHANGE_INTERRUPTION', { text: '七、服务的变更、中断、终止' }) }}</p>
			<p>
				1{{
					$t('WDNPC_WEB_IN_VIEW_OF_THE_PARTICULARITY_OF_NETWORK', {
						text:
							'、鉴于网络服务的特殊性，用户同意北京伟东凌鸿教育科技有限公司及各关联公司有权随时变更、中断或终止部分或全部的服务（包括收费服务）。北京伟东凌鸿教育科技有限公司及各关联公司变更、中断或终止的服务，北京伟东凌鸿教育科技有限公司及各关联公司应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务。',
					})
				}}
			</p>
			<p>
				2{{
					$t('WDNPC_WEB_IN_CASE_OF_ANY_OF_THE_FOLLOWING', {
						text:
							'、如发生下列任何一种情形，北京伟东凌鸿教育科技有限公司及各关联公司有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；',
					})
				}}
			</p>
			<p>{{ $t('WDNPC_WEB_THE_USER_VIOLATES_RELEVANT_LAWS_AND_REGULATIONS', { text: '用户违反相关法律法规或本协议的约定；' }) }}</p>
			<p>{{ $t('WDNPC_WEB_IN_ACCORDANCE_WITH_LEGAL_PROVISIONS_OR_THE', { text: '按照法律规定或有权机关的要求；' }) }}</p>
			<p>{{ $t('WDNPC_WEB_FOR_SECURITY_REASONS_OR_OTHER_NECESSARY_CIRCUMSTANCES', { text: '出于安全的原因或其他必要的情形。' }) }}</p>
			<p>{{ $t('WDNPC_WEB_EIGHT_OTHERS', { text: '八、其他' }) }}</p>
			<p>
				1{{
					$t('WDNPC_WEB_BEIJING_WEIDONG_LINGHONG_EDUCATION_TECHNOLOGY_CO_LTD_DFL', {
						text:
							'、北京伟东凌鸿教育科技有限公司及各关联公司郑重提醒用户注意本协议中免除北京伟东凌鸿教育科技有限公司及各关联公司责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议',
					})
				}},
				{{ $t('WDNPC_WEB_AND_USE_THIS_SERVICE_WITH_THE_CONSENT', { text: '并在其法定监护人的同意下使用本服务。' }) }}
			</p>
			<p>
				2{{
					$t('WDNPC_WEB_THE_INTERPRETATION_OF_THE_VALIDITY_OF_THIS', {
						text:
							'、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和北京伟东凌鸿教育科技有限公司及各关联公司之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交北京伟东凌鸿教育科技有限公司及各关联公司住所地有管辖权的人民法院管辖。',
					})
				}}
			</p>
			<p>
				{{
					$t('WDNPC_WEB_ANY_PROVISION_OF_THIS_AGREEMENT_IS_INVALID', {
						text: '本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。',
					})
				}}
			</p>
		</div>
	</div>
</template>
<script>
export default {
	name: 'service-agreement',
}
</script>
<style lang="stylus" scoped>
.page-service-agreement-wrap {
	background: #fff;
	padding-bottom: 60px;
	.page-service-agreement {
		width: 1200px;
		margin: 0 auto;
		h1 {
			font-size: 28px;
			line-height: 40px;
			margin-bottom: 62px;
			padding-top: 84px;
			text-align: center;
		}
		p {
			line-height: 30px;
			font-size: 20px;
			color: #000;
		}
	}
}
</style>
